import React from 'react'
import dayjs from 'dayjs'
import {
  Typography,
  Box,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import CMSText from '../Global/CMSText'

type Tprops = {
  startDate: any
  endDate: any
  deliverDuration: number
  returnDuration: number
  loading?: boolean
  t?: any
  isExpress?: boolean
  text: any
  small?: boolean
  isEnableChooseStartingDate?: boolean
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingBottom: 10,
      width: '100%',
      '&>.MuiBox-root': {
        padding: theme.spacing(1.25, 2),
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',
        '&>p': {
          fontSize: 12,
        },
        '&>span': {
          fontSize: 14,
          display: 'block',
          lineHeight: 1,
        },
      },
      [theme.breakpoints.up('lg')]: {
        display: 'inline-flex',
        padding: 0,
        marginLeft: theme.spacing(1),
        '&>.MuiBox-root': {
          margin: theme.spacing(0, 0.5),
          // width: '50%',
          // width: (StartDateSubLayout) =>
          //   !StartDateSubLayout.isStartDateSub && '50%',
        },
      },
    },
    inline: {
      display: 'inline-block',
    },
    isSmall: {
      display: 'block',
      margin: 0,
      padding: 0,
      '&>.MuiBox-root': {
        padding: 0,
        margin: theme.spacing(0, 0, 0.5),
        borderRadius: theme.shape.borderRadius,
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        background: 'none',
        width: 'auto',
        '&>p': {
          fontSize: 12,
          marginRight: theme.spacing(0.5),
        },
        '&>span': {
          fontSize: 14,
          display: 'inline-block',
          lineHeight: 1,
        },
      },
    },
    start: {
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.primaryTransparent.light,
      '&>p': {
        color: theme.palette.primaryTransparent.main,
      },
      '&>span': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
    end: {
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.primaryTransparent.light,
      '&>p': {
        color: theme.palette.primaryTransparent.main,
      },
      '&>span': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
    },
  })
)

const ShippingInfos = ({
  startDate,
  endDate,
  deliverDuration,
  returnDuration,
  loading = true,
  small,
  text,
  isExpress,
  t,
  isEnableChooseStartingDate = false,
}: Tprops) => {
  const classes = useStyles()

  return (
    <div
      className={
        small
          ? `shipping-infos ${classes.root} ${classes.isSmall}`
          : `shipping-infos ${classes.root}`
      }
    >
      <Box className={`shipping-infos__start ${classes.start}`}>
        <Typography variant="body2" component="p" noWrap>
          <CMSText
            asText
            data={text?.delivered_on}
            defaultText={t('texts:funnel:delivered_on')}
          />
        </Typography>
        <Typography variant="body1" component="span">
          {loading ? (
            <Skeleton
              variant="text"
              width="10ch"
              component="span"
              className={classes.inline}
            />
          ) : startDate ? (
            !isExpress ? (
              dayjs(startDate, 'DD/MM/YYYY')
                .add(-deliverDuration, 'day')
                .format('DD/MM/YYYY')
            ) : (
              dayjs(startDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
            )
          ) : (
            ' '
          )}
        </Typography>
      </Box>
      {!isEnableChooseStartingDate && (
        <Box className={`shipping-infos__end ${classes.end}`}>
          <Typography variant="body2" component="p" noWrap>
            <CMSText
              asText
              data={text?.return_before}
              defaultText={t('texts:funnel:return_before')}
            />
          </Typography>
          <Typography variant="body1" component="span">
            {loading ? (
              <Skeleton
                variant="text"
                width="10ch"
                component="span"
                className={classes.inline}
              />
            ) : endDate ? (
              dayjs(endDate, 'DD/MM/YYYY')
                .add(returnDuration, 'day')
                .format('DD/MM/YYYY')
            ) : (
              ' '
            )}
          </Typography>
        </Box>
      )}
    </div>
  )
}

export default ShippingInfos
