import React from 'react'
import clsx from 'clsx'
import {
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Button from '../Button/Button'
import ErrorIcon from '@material-ui/icons/Error'
import CMSText from '../Global/CMSText'
import { useRouter } from 'next/router'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
      },
    },
    container: {},
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.extraColors.greyLight,
    },
    cross: {
      cursor: 'pointer',
      height: 32,
      width: 32,
      padding: theme.spacing(0.5),
      color: theme.palette.common.white,
      backgroundColor: theme.extraColors.grey,
      borderRadius: '50%',
      margin: theme.spacing(1),
    },
    title: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    row: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      gap: theme.spacing(1),
      '& .MuiTypography-root>.paragraph-class': {
        marginTop: 0,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    warning: {},
    warningText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '80%',
      },
    },
  })
)

type Tprops = {
  lang: string
  pathname: any
  query: any
  open: boolean
  handleClose: any
  doc: any
  t: any
}

const ChangeLanguageModal = ({
  lang,
  pathname,
  query,
  open,
  handleClose,
  doc,
  t,
}: Tprops) => {
  const classes = useStyles()
  const router = useRouter()
  const { trackEvent } = useMatomo()
  const trackLangChange = async (lang) => {
    trackEvent({
      category: 'Header',
      href: '/account',
      action: `Change in ${lang.toUpperCase().substring(0, 2)}`,
    })
  }
  const closeLanguageModalAndChangeLanguage = () => {
    router.push(
      { pathname: pathname, query: query },
      { pathname: pathname },
      { locale: lang }
    )
    handleClose()
    trackLangChange(lang)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      className={classes.root}
      scroll={'paper'}
      disableBackdropClick
    >
      <div className={classes.container}>
        <div className={`${classes.modalHeader}`}>
          <Typography
            variant="h5"
            component="h2"
            className={`${classes.title}`}
          >
            <CMSText
              asText
              data={doc?.data?.change_language_modal_title}
              defaultText={t('texts:general:change_language_modal_title')}
            />
          </Typography>
          <CloseIcon className={classes.cross} onClick={handleClose} />
        </div>
        <DialogContent dividers>
          <div className={clsx(classes.row, classes.warning)}>
            <ErrorIcon color={'error'} />
            <Typography
              className={clsx(classes.row, classes.warningText)}
              component={'div'}
            >
              <CMSText
                asText
                data={doc?.data?.change_language_modal_text}
                defaultText={t('texts:general:change_language_modal_text')}
              />
            </Typography>
          </div>
          <div className={classes.row}>
            <Button onClick={handleClose}>
              <CMSText
                asText
                data={doc?.data?.change_language_modal_cancel_text}
                defaultText={t(
                  'texts:general:change_language_modal_cancel_text'
                )}
              />
            </Button>
            <Button
              variant={'contained'}
              color={'secondary'}
              onClick={closeLanguageModalAndChangeLanguage}
            >
              <CMSText
                asText
                data={doc?.data?.change_language_modal_validation_text}
                defaultText={t(
                  'texts:general:change_language_modal_validation_text'
                )}
              />
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default ChangeLanguageModal
